






















































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  address = '';
  student = '';
  doneWaiting = false;
  window = '';

  get foundAddress() {
    return this.address.toLowerCase().includes('400');
  }

  get foundStudent() {
    return this.student.toLowerCase().includes('potter');
  }

  get foundWindow() {
    return this.window.toLowerCase().includes('fan');
  }

  @Watch('foundStudent')
  onFoundStudentChange(newFound: boolean) {
    if (newFound) {
      setTimeout(() => {
        this.doneWaiting = true;
        (this.$refs.audio as any).play();
      }, 60 * 1000);
    }
  }
}
